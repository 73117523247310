<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="py-0">
            <span class="font-weight-bold space-text">Ubicación</span>
            <v-divider class="my-3"></v-divider>
          </v-col>
        </v-row>
        <show-map :lat="lat" :lng="lng" height="300px" v-if="lat != ''"/>
        <v-row>
          <v-col cols="12" class="py-0 mt-3">
            <span class="font-weight-bold space-text">Detalles</span>
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="12" class="py-0">W
              <p :v-text="description"></p>
            <v-list class="py-0" dense>
              <v-list-item>
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-map-marker</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text" v-text="address"></v-list-item-title>
                  <v-list-item-title class="space-text" v-text="city+' '+provincia"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-phone</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text" v-text="phone"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-email</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text" v-text="email"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="facebook!=='' && facebook !== null">
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-facebook</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text"><a :href="facebook" target="_blank">Facebook</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="instagram !== '' && instagram !== null">
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-instagram</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text"><a :href="instagram" target="_blank">Instagram</a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="whatsapp !== '' && whatsapp !== null">
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-whatsapp</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text"><a :href="'https://api.whatsapp.com/send?phone='+whatsapp+'&text=Deseo%20tener%20m%C3%A1s%20informaci%C3%B3n'" target="_blank" v-text="whatsapp"></a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-if="url_allergen != 'null'">
          <v-col cols="12" class="py-0 mt-8">
            <span class="font-weight-bold space-text">Alérgenos</span>
              <v-divider class="my-3"></v-divider>
            </v-col>
            <v-col cols="12" class="py-0 mb-12">
            <v-list class="py-0" dense>
              <v-list-item>
                <v-list-item-action class="mr-1">
                  <v-icon small color="grey lighten-1">mdi-web</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="space-text">
                    <a href="//" target="_blank" @click.prevent="external" v-text="url_allergen"></a>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import ShowMap from '../../components/ShowMap'
  import { mapGetters} from 'vuex'
  export default {
    name: 'Promotions',
    components: { ShowMap },
    data: () => ({
      promotions: [],
      load_promotions: false,
      url_img: process.env.VUE_APP_IMG_URL_API
    }),
    methods:{
      async getPromotions(){
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        this.load_promotions = true;
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/promotions`);

          this.promotions = data.data.restaurant.promotions;
        }
        catch(e){
          if(e.response.data.error_code == '400'){
            this.$router.push({name: '404', query: {msj: e.response.data.message}})
          }
          else{
            this.$router.push({name: '404'})
          }
        }
        finally {
          this.load_promotions = false;
        }
      },
      external(){
        window.location.href = 'http://'+this.url_allergen;
      },
    },
    computed: {
      ...mapGetters({
        language: 'language',
        logo: 'landing/logo',
        image_header: 'landing/header',
        name_restaurant: 'landing/name',
        address: 'landing/address',
        city: 'landing/city',
        province: 'lading/province',
        phone: 'landing/phone',
        email: 'landing/email',
        facebook: 'landing/facebook',
        instagram: 'landing/instagram',
        whatsapp: 'landing/whatsapp',
        description: 'landing/description',
        lat: 'landing/lat',
        lng: 'landing/lng',
        url_allergen: 'landing/url_allergen'
      }),
      imgMenu(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '50vh' : '45vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      name_menu(){
        if(this.detailsMenu.name)
          return this.detailsMenu.name[this.$store.getters.language] ? this.detailsMenu.name[this.$store.getters.language] : this.detailsMenu.name.es;
        return '';
      },
      imgPromotion(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '40vh' : '30vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
<style lang="sass">
  .title_promotion
    font-size: 1rem
    font-weight: 500
    line-height: 1.5rem
    padding-top: 12px
    padding-bottom: 12px
    margin: 0 !important
    letter-spacing: 1px


</style>
