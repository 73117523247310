<template>
  <v-row>
    <v-col cols="12">
      <gmap-map
        :center="center"
        :zoom="15"
        :style="styleMap"
      >
        <gmap-marker
          :position="markers.position"
          @click="center=markers.position"
        ></gmap-marker>
      </gmap-map>      
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ShowMap",
  props: {
      height: {
        type: String,
      },
      lat: {
        type: Number,
        default: 0,
      },
      lng: {
        type: Number,
        default: 0,
      },
    },
  data() {
    return {
      // default to Montreal to keep it simple
      // change this to whatever makes sense
      center: { lat: 45.508, lng: -73.587 },
      markers: '',
    };
  },

  mounted() {
    if(this.lat != '' && this.lng != ''){
      this.center = {
        lat: this.lat,
        lng: this.lng
      };
      this.markers = { position: this.center };
    }
    else
      this.geolocate();
  },

  methods: {
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  },
  computed:{
    styleMap(){
      return {
        height: this.height,
        width: '100%'
      }
    }
  },
  filters:{
    json(val){
      return JSON.stringify(val)
    }
  }
};
</script>